import React from 'react';
import { formatDate } from '../../services/DateFormatter';
import { Card, CardText, Col, Row } from 'reactstrap';

export const Channel = ({ channel }) => (
    <Row>
        <Col sm={4}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={4}>
                            <dt>Id</dt>
                            <dd>{channel.id}</dd>
                        </Col>
                        <Col sm={8}>
                            <dt>Name</dt>
                            <dd>{channel.name}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={8}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={4}>
                            <dt>Created at</dt>
                            <dd>{formatDate(channel.createdAt)}</dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Updated at</dt>
                            <dd>{formatDate(channel.updatedAt)}</dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Released at</dt>
                            <dd>{formatDate(channel.releasedAt)}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={12}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={4}>
                            <dt>Website</dt>
                            <dd>{channel.website && <a target="_blank" rel="noopener noreferrer" href={channel.website}>{channel.website}</a>}</dd>
                        </Col>
                        <Col sm={8}>
                            <dt>Logo URL</dt>
                            <dd>{channel.logoUrl}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={6}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={4}>
                            <dt>TV</dt>
                            <dd><input type="checkbox" checked={channel.tv} readOnly /></dd>
                        </Col>
                        <Col sm={4}>
                            <dt>HD</dt>
                            <dd><input type="checkbox" checked={channel.hd} readOnly /></dd>
                        </Col>
                        <Col sm={4}>
                            <dt>UHD</dt>
                            <dd><input type="checkbox" checked={channel.uhd} readOnly /></dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Free</dt>
                            <dd><input type="checkbox" checked={channel.free} readOnly /></dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Free conditional</dt>
                            <dd><input type="checkbox" checked={channel.freeConditional} readOnly /></dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Service charge</dt>
                            <dd><input type="checkbox" checked={channel.serviceCharge} readOnly /></dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={6}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={6}>
                            <dt>Satellite</dt>
                            <dd>{channel.satellite}</dd>
                        </Col>
                        <Col sm={6}>
                            <dt>Orbital Position</dt>
                            <dd>{channel.orbitalPosition}</dd>
                        </Col>
                        <Col sm={6}>
                            <dt>Polarization</dt>
                            <dd>{channel.polarization}</dd>
                        </Col>
                        <Col sm={6}>
                            <dt>Footprint</dt>
                            <dd>{channel.footprint}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={12}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={3}>
                            <dt>Standard</dt>
                            <dd>{channel.standard}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Codec</dt>
                            <dd>{channel.codec}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Encryption</dt>
                            <dd>{channel.encryption}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Transponder number</dt>
                            <dd>{channel.transponderNumber}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Frequency</dt>
                            <dd>{channel.frequency}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Symbol rate</dt>
                            <dd>{channel.symbolRate}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Fec</dt>
                            <dd>{channel.fec}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>

        <Col sm={12}>
            <Card body className='mb-4'>
                <CardText tag="div">
                    <Row>
                        <Col sm={4}>
                            <dt>Genre</dt>
                            <dd>{channel.genre}</dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Target Markets</dt>
                            <dd>{channel.targetMarkets}</dd>
                        </Col>
                        <Col sm={4}>
                            <dt>Kmz</dt>
                            <dd>{channel.kmz}</dd>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>
    </Row>
);