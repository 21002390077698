import axios from 'axios';

export function fetchManufacturers() {
    return axios.get(`/webservice/backoffice/manufacturers`).then(response => response.data);
}

export function fetchManufacturer(id) {
    return axios.get(`/webservice/backoffice/manufacturers/${id}`).then(response => response.data);
}

export function createManufacturer(formData) {
    return axios.post(`/webservice/backoffice/manufacturers`, formData);
}

export function updateManufacturer(id, formData) {
    return axios.post(`/webservice/backoffice/manufacturers${id}`, formData);
}

export function deleteManufacturer(id) {
    return axios.delete(`/webservice/backoffice/manufacturers/${id}`);
}