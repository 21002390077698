import * as React from 'react'
import TeleportForm from '../../components/teleports/TeleportForm';
import {fetchTeleport, updateTeleport, createTeleport, deleteTeleport} from '../../actions/teleports';
import {fetchFootprints} from '../../actions/footprints';
import {fetchConstellations} from '../../actions/constellations';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const TeleportEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'teleport',
            rootPath: '/teleports',
            default: INITIAL_DATA,
            promise: (id) => fetchTeleport(id)    
        }}
        secDataInfos={[
            {name: 'footprints', title: 'footprints', promise: () => fetchFootprints()},
            {name: 'constellations', title: 'constellations', promise: () => fetchConstellations()}
        ]}
        formComponent={(props) => ( <TeleportForm {...props} /> )}
        updateCallback={updateTeleport} 
        createCallback={createTeleport}   
        deleteCallback={deleteTeleport}
    />
)

export default TeleportEditPage;

