import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';

class OrbitalPositionForm extends FormBase {
    render() {
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('position', {label: 'Position'})}</Col>
                        <Col sm={6}>{this.select('direction', {'east':'east', 'west': 'west'}, {label: 'Direction', placeholder: 'No direction'})}</Col>
                    </Row>
                )}

                {this.formCard("Household Reach",
                    <Row>
                        <Col sm={3}>{this.number('nbReachedSatellites', {label: 'Satellite (millions)'})}</Col>
                        <Col sm={3}>{this.number('nbReachedCables', {label: 'Cable (millions)'})}</Col>
                        <Col sm={3}>{this.number('nbReachedIptvs', {label: 'IPTV (millions)'})}</Col>
                        <Col sm={3}>{this.number('nbReachedTotal', {label: 'Reach total'})}</Col>
                    </Row>
                )}

                {this.formCard("Extra information",
                    <Row>
                        <Col sm={6}>{this.textArea('description', {label: 'Description'})}</Col>
                        <Col sm={6}>{this.textArea('keyFeatures', {label: 'Key features'})}</Col>
                    </Row>
                )}
            </Form>
        );
    }
}

export default OrbitalPositionForm