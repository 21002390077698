import React, {Component} from 'react'
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import {Link} from 'react-router-dom';
import logo from '../../logo.svg';
import './Header.css';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.state = {
            isOpen: false
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    onLogout() {
        const { onLogout } = this.props;
        onLogout && onLogout();
    }

    navLink = (link, label) => (
        <NavLink tag={Link} to={link} active={this.props.location.pathname.startsWith(link)}>{label}</NavLink>
    );

    dropdownItem = (link, label) => (
        <DropdownItem tag={Link} to={link} active={this.props.location.pathname.startsWith(link)}>{label}</DropdownItem>
    );

    render() {
        const { user } = this.props;
        return (
            <Navbar color="light" expand="md" className="ses-header navbar-light mb-4" sticky="top">
                <div className="container">
                    <NavbarBrand tag={Link} to="/"><img src={logo} alt="SES Satellites" height={35}/></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav navbar className="mr-auto">
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret>
                                    Satellites
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.dropdownItem('/satellites', 'Satellites')}
                                    {this.dropdownItem('/constellations', 'Constellations')}
                                    {this.dropdownItem('/launches', 'Launches')}
                                    {this.dropdownItem('/vehicles', 'Vehicles')}
                                    {this.dropdownItem('/manufacturers', 'Manufacturers')}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav>
                                <DropdownToggle nav caret>
                                Footprints
                                </DropdownToggle>
                                <DropdownMenu >
                                    {this.dropdownItem('/footprints', 'Footprints')}
                                    {this.dropdownItem('/geo_jsons', 'Geo-jsons')}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem className="mr-auto">
                                {this.navLink('/bands', 'Bands')}
                            </NavItem>
                            <NavItem className="mr-auto">
                                {this.navLink('/orbital_positions', 'Orbital positions')}
                            </NavItem>
                            <NavItem className="mr-auto">
                                {this.navLink('/teleports', 'Teleports')}
                            </NavItem>
                            <NavItem className="mr-auto">
                                {this.navLink('/pops', 'Pops')}
                            </NavItem>
                            <NavItem className="mr-auto">
                                {this.navLink('/channels', 'Channels')}
                            </NavItem>
                        </Nav>
                        { user && (
                            <Nav navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle nav caret>
                                        {user.name}
                                    </DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem onClick={this.props.onLogout}>
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Nav>
                        )}
                    </Collapse>
                </div>
            </Navbar>
        )
    }
}