import * as React from 'react'
import CommonEditPage from '../shared/CommonEditPage';
import PopForm from "../../components/pops/PopForm";
import {fetchPop, updatePop, createPop, deletePop} from '../../actions/pops';

const INITIAL_DATA = {};

const PopEditPage = (props) => (
    <CommonEditPage
        {...props}

        mainDataInfo={{
            name: 'data',
            title: 'pop',
            rootPath: '/pops',
            default: INITIAL_DATA,
            promise: (id) => fetchPop(id)
        }}
        formComponent={(props) => (<PopForm {...props} />)}
        updateCallback={updatePop}
        createCallback={createPop}
        deleteCallback={deletePop}
    />
)

export default PopEditPage;

