import React, {Component} from 'react'
import Spinner from '../../components/shared/Spinner';
import {ErrorAlert} from '../../components/shared/ErrorAlert';
import {Channel} from '../../components/channels/Channel';
import {fetchChannel} from '../../actions/channels';

export default class ChannelPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {loading: true};
    }

    componentDidMount() {
        fetchChannel(this.props.match.params.id)
            .then(channel => this.setState({
                channel: channel,
                loading: false
            }))
            .catch(error => this.setState({
                error: new Error(`An error occurred when retrieving the channel.`),
                loading: false
            }));
    }

    render() {
        const {channel, error, loading} = this.state;
        return (
            <div>
                <div className="mt-2">
                    <ErrorAlert error={error}/>
                </div>
                { loading && <div className="w-100 text-center"><Spinner spinning={true} size='large' /></div>}
                { !loading && channel && <div>
                    <h1>{channel.name}</h1>
                    <Channel channel={channel} />
                </div>}
            </div>
        )
    }
}
