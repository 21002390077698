import axios from 'axios';

export function fetchBands() {
    return axios.get(`/webservice/backoffice/bands`).then(response => response.data);
}

export function fetchBand(id) {
    return axios.get(`/webservice/backoffice/bands/${id}`).then(response => response.data);
}

export function createBand(formData) {
    return axios.post(`/webservice/backoffice/bands`, formData);
}

export function updateBand(id, formData) {
    return axios.post(`/webservice/backoffice/bands/${id}`, formData);
}

export function deleteBand(id) {
    return axios.delete(`/webservice/backoffice/bands/${id}`);
}