import * as React from 'react'
import GeoJsonForm from '../../components/geo_jsons/GeoJsonForm';
import {fetchGeoJson, updateGeoJson, createGeoJson, deleteGeoJson} from '../../actions/geoJsons';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const GeoJsonEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'geo-json',
            rootPath: '/geo_jsons',
            default: INITIAL_DATA,
            promise: (id) => fetchGeoJson(id)    
        }}
        formComponent={(props) => ( <GeoJsonForm {...props} /> )}
        updateCallback={updateGeoJson} 
        createCallback={createGeoJson}   
        deleteCallback={deleteGeoJson} 
    />
)

export default GeoJsonEditPage;
