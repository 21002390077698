import React from 'react'
import { SimpleListPage } from '../shared/SimpleListPage';
import { fetchConstellations } from '../../actions/constellations';

const ConstellationEditPage = () => (
    <SimpleListPage
        dataUrl={'constellations'}
        dataName='constellation'
        fetchData={fetchConstellations}
        columns={[
            { field: 'name', label: 'Name' },
            { field: 'satellitesCount', label: 'Satellites Count' },
        ]}
        defaultSorted={[
            { id: 'name' }
        ]}
        pageSize={100}
    />
);

export default ConstellationEditPage;
