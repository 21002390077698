import React, {Component} from 'react'
import {Alert, Form} from 'reactstrap';
import authService from '../../services/AuthService';
import {toast} from 'react-toastify';
import {Redirect} from 'react-router-dom';
import {FormInput, FormSubmitButton} from "../../components/shared/FormComponents"

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            submitting: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { onLogin } = this.props;
        const { username, password } = this.state;

        this.setState({submitting: true}, () =>
            authService.login(username, password)
                .then(() => {
                    const { from } = this.props.location.state || { from: { pathname: '/' } };
                    onLogin && onLogin();
                    toast.success('Welcome back !');
                    this.props.history.push(from);
                })
                .catch(() => {
                    this.setState({
                        error: 'Failed to login',
                        submitting: false
                    });
                })
        );
    }

    render() {
        const {username, password, error} = this.state;
        if (authService.isLoggedIn()) {
            return (<Redirect to='/' />)
        }
        return (
            <div>
                <h1>Login</h1>

                { this.state.error && <Alert color="danger">{error}</Alert> }

                <Form>
                    <FormInput id='username' type='text' label='Username' value={username} onChange={(_, v) => this.setState({username: v})}/>
                    <FormInput id='password' type='password' label='Password' value={password} onChange={(_, v) => this.setState({password: v})}/>
                    <FormSubmitButton label='Login' onSubmit={this.onSubmit} submitting={this.state.submitting}/>
                </Form>
            </div>
        )
    }
}
