import axios from 'axios';

export function fetchLaunches() {
    return axios.get(`/webservice/backoffice/launches`).then(response => response.data);
}

export function fetchLaunch(id) {
    return axios.get(`/webservice/backoffice/launches/${id}`).then(response => response.data);
}

export function createLaunch(formData) {
    return axios.post(`/webservice/backoffice/launches`, formData);
}

export function updateLaunch(id, formData) {
    return axios.post(`/webservice/backoffice/launches/${id}`, formData);
}

export function deleteLaunch(id) {
    return axios.delete(`/webservice/backoffice/launches/${id}`);
}