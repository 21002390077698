import axios from 'axios';

export function fetchSatellites(params) {
    return axios.get(`/webservice/backoffice/satellites`,  { params: params }).then(response => response.data);
}

export function fetchSatellite(id) {
    return axios.get(`/webservice/backoffice/satellites/${id}`).then(response => response.data);
}

export function createSatellite(formData) {
    return axios.post(`/webservice/backoffice/satellites`, formData);
}

export function updateSatellite(id, formData) {
    return axios.post(`/webservice/backoffice/satellites/${id}`, formData);
}

export function deleteSatellite(id) {
    return axios.delete(`/webservice/backoffice/satellites/${id}`);
}

export function restoreSatellite(id) {
    return axios.post(`/webservice/backoffice/satellites/${id}/restore`);
}

export function duplicateSatellite(formData) {
    return axios.post(`/webservice/backoffice/satellites/duplicate`, formData);
}