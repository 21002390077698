import * as React from 'react';
import { FormBase } from '../shared/FormBase';
import BandsList from '../../components/satellites/BandsList';
import {Alert, CardDeck, Col, Form, NavLink, Row, Button} from 'reactstrap';
import { objListToMap } from '../../services/MapExtractor';
import {Link} from "react-router-dom";

export default class SatelliteForm extends FormBase {

    componentDidUpdate(prevProps) {
        // If change GEO <-> MEO
        if (this.props.data.type !== prevProps.data.type) {
            const { data, defaultData } = this.props;
            const geoValues = ['inclined', 'orbitalPositionId', 'transpondersKaBand', 'transpondersKuBand', 'transpondersCBand'];

            // Some options are useless for MEO satellites
            if (data.type === 'MEO') {
                geoValues.forEach(value => {
                    delete data[value];
                });
                data['coverageAreaIds'] = ['worldwide'];
            }
            // Restore default values if GEO is selected
            if (data.type === 'GEO') {
                geoValues.forEach(value => {
                    data[value] = defaultData[value];
                });
            }
        }
    }

    render() {
        const { data, manufacturers, orbitalPositions, coverageAreas, footprints, constellations, launches } = this.props;
        return (
            <Form>
                {this.getValue("deleted") ? <Alert color="warning">This satellite is marked as deleted. You can <Button className="button-link alert-link" onClick={() => this.restoreSatellite()}>restore it</Button>.</Alert> : null}
                {this.formCard("General",
                    <Row>
                        <Col sm={12}>{this.select('type', ['GEO', 'MEO'], { label: 'Satellite type', useIndexAsValue: false })}</Col>
                        <Col sm={6}>{this.input('name', { label: 'Name' })}</Col>
                        <Col sm={6}>{this.input('title', { label: 'Title' })}</Col>

                        <Col sm={12}>{this.select('launchId', objListToMap(launches, 'id', 'name'), { label: 'Launch', placeholder: 'Please select...', withReload: true, additionalData: this.openPageWindow("/launches/new", "Create a launch")})}</Col>

                        <Col sm={6}>{this.number('designLife', { label: 'Design life', min: 0 })}</Col>
                        <Col sm={6}>{this.select('manufacturerId', objListToMap(manufacturers, 'id', 'title'), { label: 'Manufacturer', placeholder: 'Please select...' })}</Col>

                        <Col sm={2}>{this.checkBox('commercialized', { label: 'Commercialized' })}</Col>
                    </Row>
                )}

                <CardDeck>
                    {data.type === 'GEO' && this.formCard("Orbital positions",
                        <div>
                            {this.select('orbitalPositionId', objListToMap(orbitalPositions, 'id', 'name'), { label: 'Position', placeholder: 'Please select...' })}
                            {this.multiSelect('coverageAreaIds', objListToMap(coverageAreas, 'id', 'name'), { label: 'Coverage Areas' })}
                        </div>
                    )}
                    {this.formCard("Image",
                        <div>
                            {data.imageUrl && <img src={data.imageUrl} alt='satellite' />}
                            {this.fileChooser('image', { label: 'Change image' })}
                        </div>
                    )}
                    {data.type === 'MEO' && this.formCard("Coverage Areas",
                        this.select('coverageAreaIds', objListToMap(coverageAreas, 'id', 'name'), { label: null, disabled: true, value: 'worldwide' })
                    )}
                </CardDeck>

                {data.footprintIds && data.footprintIds.length > 0 && this.formCard("Footprints",
                    this.formListOrderer('footprintIds', objListToMap(footprints, 'id', 'name'), {}))}

                {data.bands && data.bands.length > 0 && this.formCard("Bands", <BandsList bands={data.bands} />)}

                {data.type === 'GEO' && this.formCard("Number of transponders",
                    <Row>
                        <Col sm={4}>{this.number('transpondersCBand', { label: 'C-band', min: 0 })}</Col>
                        <Col sm={4}>{this.number('transpondersKaBand', { label: 'Ka-band', min: 0 })}</Col>
                        <Col sm={4}>{this.number('transpondersKuBand', { label: 'Ku-band', min: 0 })}</Col>
                    </Row>
                )}

                {data.type === 'MEO' && this.formCard("Constellation",
                    <Row>
                        <Col sm={6}>{this.select('constellationId', objListToMap(constellations, 'id', 'name'), { label: 'Constellation', placeholder: 'Please select...', withReload: true, additionalData: this.openPageWindow("/constellations/new", "Create a constellation")})}</Col>
                    </Row>
                )}

                {this.formCard("Extra information",
                    <Row>
                        <Col sm={6}>{this.textArea('description', { label: 'Description' })}</Col>
                        <Col sm={6}>{this.textArea('bandComment', { label: 'Band comment' })}</Col>
                    </Row>
                )}
            </Form>
        );
    }

    restoreSatellite() {
        const {onRestoreSubmit} = this.props;
        onRestoreSubmit && onRestoreSubmit(this.getValue("id"));
    }

    openPageWindow(link, label, automaticClose = true) {
        return <NavLink tag={Link} to={`${link}${automaticClose ? '?close=true' : ''}`} target="_blank">{label}</NavLink>;
    }
}