import React, {Component} from 'react'
import {CommonListPage} from './CommonListPage';

export class PaginatedListPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        };

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData({pageSize, page, sorted, filtered}) {
        this.setState({loading: true, error: null}, () => {
            this.props.fetchData(pageSize, page, sorted, filtered)
                .then(res => this.setState({
                    data: res.content,
                    dataSize: res.totalElements,
                    nbPages: res.totalPages
                }))
                .catch(error => this.setState({error: error}))
                .then(() => this.setState({loading: false}));
        });
    }

    render() {
        return (
            <CommonListPage {...this.props} {...this.state} onFetchData={this.fetchData} />
        )
    }
}