import axios from 'axios';
import decode from 'jwt-decode';
import getAppConfig from '../config';
import history from '../history';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const API_BASE_URL = getAppConfig().API_BASE_URL;

const clientId = getAppConfig().CLIENT_ID;
const clientSecret = getAppConfig().CLIENT_SECRET;

class AuthService {
    constructor() {
        init();
    }

    login(username, password) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', 'password');

        return authenticate(formData);
    }

    logout() {
        clearTokens();
    }

    canRefreshToken() {
        return canRefreshToken();
    }

    isLoggedIn() {
        return isLoggedIn();
    }

    getUser() {
        const accessToken = getAccessToken();
        if (!!accessToken) {
            return {
                name: decode(accessToken).full_user_name
            };
        }
        return null;
    }
}

function isLoggedIn() {
    const accessToken = getAccessToken();
    return !!accessToken && !isTokenExpired(accessToken);
}

function canRefreshToken() {
    const refreshToken = getRefreshToken();
    return !!refreshToken && !isTokenExpired(refreshToken);
}

function init() {
    axios.interceptors.request.use(
        config => {
            if (isLoggedIn()) {
                config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            const request = error.config;
            if (401 === error.response.status && request && !request.refresh) {
                return refresh()
                    .then(jwt => axios.request(error.config))
                    .catch(error => {
                        clearTokens();
                        history.push({pathname: '/login', state: {from: history.location}});
                        return Promise.reject(error);
                    });
            }
            return Promise.reject(error);
        });
}


function authenticate(formData, refresh = false) {
    const config = {
        headers: {
            'Authorization' : `Basic ${btoa(`${clientId}:${clientSecret}`)}`
        },
        refresh: refresh
    };

    return axios.post(`${API_BASE_URL}/webservice/auth/token`, formData, config)
        .then(response => response.data)
        .then(data => {
            setAccessToken(data.access_token);
            setRefreshToken(data.refresh_token);
            return data;
        });
}

function refresh() {
    const refreshToken = getRefreshToken();

    if (isTokenExpired(refreshToken)) {
        return Promise.reject('Refresh token is expired');
    }

    const formData = new FormData();
    formData.append('refresh_token', refreshToken);
    formData.append('grant_type', 'refresh_token');

    return authenticate(formData, true);
}


function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearTokens() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

function setAccessToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken ? accessToken : '');
}

function setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken ? refreshToken : '');
}

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function isTokenExpired(token) {
    const expirationDate = getTokenExpirationDate(token);
    return expirationDate < new Date();
}

const authService = new AuthService();

export default authService;