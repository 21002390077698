import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {CardDeck, Form} from 'reactstrap';
import {Link} from 'react-router-dom';

class GeoJsonForm extends FormBase {
    render() {
        const {data} = this.props;
        return (
            <Form>
                <CardDeck>
                    {this.formCard("General",
                        (this.input('name', {label: 'Name'}))
                    )}
                    {this.formCard("File",
                        <div>
                            { data.fileUrl && <Link target="_blank" to={data.fileUrl}>{data.fileUrl}</Link> }
                            {this.fileChooser('file', {label: 'Change file'})}
                        </div>
                    )}
                </CardDeck>
            </Form>
        );
    }
}

export default GeoJsonForm