import * as React from 'react';
import { FormBase } from '../shared/FormBase';
import { Col, Form, Row } from 'reactstrap';
import { objListToMap } from '../../services/MapExtractor';

export default class SatelliteForm extends FormBase {

    render() {
        const { satellites } = this.props;
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.select('satelliteId', objListToMap(satellites, 'id', 'title'), { label: 'Satellite source', placeholder: 'Please select...' })}</Col>
                        <Col sm={6}>{this.number('count', { label: 'Number of duplicates', min: 1, max: 100 })}</Col>
                    </Row>
                )}
            </Form>
        );
    }
}