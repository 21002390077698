import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchOrbitalPositions} from '../../actions/orbitalPositions';

const OrbitalPositionListPage = () => (
    <SimpleListPage
        dataUrl={'orbital_positions'}
        dataName='orbital position'
        fetchData={fetchOrbitalPositions}
        columns={[
            {field: 'name', label: 'Name'}
        ]}
        pageSize={100}
    />
);

export default OrbitalPositionListPage;