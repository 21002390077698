import React, { Component } from 'react';
import { Button, FormGroup, FormText, Input, Label, ListGroup, ListGroupItem, InputGroup, InputGroupAddon } from 'reactstrap';
import { Link } from 'react-router-dom';
import OpenOnFocusDatePicker from './OpenOnFocusDatePicker';
import classnames from 'classnames';
import Spinner from './Spinner';
import MultiSelect from "@kenshooui/react-multi-select";
import './FormComponents.css'
import {ArrowCounterclockwise} from "react-bootstrap-icons";

const BaseLabel = (props) => {
    const { id, label, additionalData } = props;
    return <div className={classnames({"base-label": true})}>
        <Label for={id}>{label}</Label>
        { additionalData }
    </div>
}

export class FormInput extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        onChange && onChange(this.props.id, event.target.value);
    }

    render() {
        const { id, label, placeholder, value, invalid, type, helpText, min, max, additionalData } = this.props;
        return (
            <FormGroup>
                <BaseLabel id={id} label={label} additionalData={additionalData}/>
                <Input id={id} type={type} value={isDefined(value) ? value : ''} onChange={this.onChange}
                    className={classnames({ 'is-invalid': invalid })} placeholder={placeholder} min={min} max={max} />
                {helpText && <FormText>{helpText}</FormText>}
            </FormGroup>
        );
    }
}

function isDefined(value) {
    return !!value || value === 0;
}

export class FormDate extends Component {
    // Be careful ! We make the assumption here we'll always include at least the decade.
    formats = {"month": "DD", "year": "MM", "decade": "YYYY", "century": ""}
    views = ["month", "year", "decade", "century"]

    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        const { onChange, type } = this.props;
        value = this.ignoreGMT(type !== "complete" ? this.toLastDayOfMonth(value) : value);
        console.log(value);
        onChange && onChange(this.props.id, value);
    }

    toLastDayOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth()+1, 0, 0, 0);
    }

    render() {
        const { id, label, value, invalid, type, additionalData } = this.props;
        const views = type === "complete" ? this.views : this.views.slice(1);
        const format = views.map((e) => this.formats[e]).filter((e) => e.length > 0).join("/") 
        return (
            <FormGroup>
                <BaseLabel id={id} label={label} additionalData={additionalData}/>
                <OpenOnFocusDatePicker
                    id={id}
                    value={value ? value : undefined}
                    onChange={this.onChange}
                    className={classnames({ 'is-invalid': invalid })}
                    format={format}
                    views={views}
                    footer={views.length === 0}
                    placeholder={format}
                    required
                />
            </FormGroup>
        );
    }

    ignoreGMT(date) {
        date.setHours(0, 0, 0, 0);
        var tzoffset = (date).getTimezoneOffset() * 60000;
        return new Date(date - tzoffset);
    }
}

export class FormSelect extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.onReloading = this.onReloading.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        onChange && onChange(this.props.id, event.target.value);
    }

    onReloading(_) {
        const { onReloading } = this.props;
        onReloading();
    }

    render() {
        const { id, label, disabled, placeholder, value, allowedValues, invalid, useIndexAsValue, additionalData, withReload } = this.props;
        return (
            <FormGroup>
                {label ? <BaseLabel id={id} label={label} additionalData={additionalData}/> : null}
                <InputGroup>
                    <Input type="select" id={id} value={value ? value : ''} onChange={this.onChange} disabled={disabled}
                        className={classnames({ 'is-invalid': invalid })}>
                        {placeholder ? <option value=''>{placeholder}</option> : null}
                        {
                            Object.entries(allowedValues).map(e => (
                                <option key={e[0]} value={useIndexAsValue ? e[0] : e[1]}>{e[1]}</option>
                            ))
                        }
                    </Input>
                    {withReload ?
                        <InputGroupAddon addonType="append">
                            <Button type="button" onClick={this.onReloading}><ArrowCounterclockwise /></Button>
                        </InputGroupAddon> : null
                    }
                </InputGroup>
            </FormGroup>
        );
    }
}

export class FormMultiSelect extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        const values = [...event.target.selectedOptions].map(option => option.value);
        onChange && onChange(this.props.id, values);
    }

    render() {
        const { id, label, value, allowedValues, invalid } = this.props;
        return (
            <FormGroup>
                <Label for={id}>{label}</Label>
                <Input type="select" id={id} value={value ? value : []} onChange={this.onChange}
                    className={classnames({ 'is-invalid': invalid })}
                    multiple>
                    {
                        Object.entries(allowedValues).map(e => (
                            <option key={e[0]} value={e[0]}>{e[1]}</option>
                        ))
                    }
                </Input>
            </FormGroup>
        );
    }
}

export class FormMultiSelectDualList extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        const { onChange, value, id } = this.props;
        this.state = {
            values: value,
        }
        if(value)
            onChange && onChange(id, value.map(v => v.id));
    }

    onChange(selected) {
        const { onChange } = this.props;
        this.setState({values: selected})
        onChange && onChange(this.props.id, selected.map(s => s.id));
    }

    render() {
        const { id, label, allowedValues, invalid, withGrouping } = this.props;
        return (
            <FormGroup>
                <Label for={id}>{label}</Label>
                <MultiSelect
                    onChange={this.onChange} 
                    selectedItems={this.state.values} 
                    items={allowedValues} 
                    className={classnames({ 'is-invalid': invalid })}
                    withGrouping={withGrouping}
                />
            </FormGroup>
        );
    }
}

export class FormListOrderer extends Component {
    constructor(props, context) {
        super(props, context);
        this.onMoveUpElement = this.onMoveUpElement.bind(this);
        this.onMoveDownElement = this.onMoveDownElement.bind(this);
    }

    onMoveDownElement(idx) {
        const { onChange, value } = this.props;
        const newValue = [...value];
        newValue.splice(idx, 1);
        newValue.splice(idx + 1, 0, value[idx]);
        onChange && onChange(this.props.id, newValue);
    }

    onMoveUpElement(idx) {
        const { onChange, value } = this.props;
        const newValue = [...value];
        newValue.splice(idx, 1);
        newValue.splice(idx - 1, 0, value[idx]);
        onChange && onChange(this.props.id, newValue);
    }

    render() {
        const { id, label, value, allowedValues } = this.props;
        const elements = value ? value : [];
        return (
            <ListGroup>
                <Label for={id}>{label}</Label>
                {elements.map((element, idx) => (
                    <ListGroupItem key={idx} className="d-flex justify-content-between align-items-center">
                        <span className="ml-2">
                            <Button type='button' onClick={() => this.onMoveUpElement(idx)} outline disabled={idx === 0}>
                                <i className={classnames('fa', 'fa-arrow-up')} />
                            </Button>
                            <Button type='button' onClick={() => this.onMoveDownElement(idx)} outline disabled={idx === elements.length - 1}>
                                <i className={classnames('fa', 'fa-arrow-down')} />
                            </Button>
                        </span>
                        <span>{allowedValues[element] ? allowedValues[element] : ''}</span>
                        <Link to={`/footprints/${element}`} className="ml-2">
                            <Button type="button" outline>
                                Edit
                            </Button>
                        </Link>
                    </ListGroupItem>
                ))}
            </ListGroup>
        );
    }
}

export class FormCheckBox extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        onChange && onChange(this.props.id, event.target.checked);
    }

    render() {
        const { id, label, value, invalid } = this.props;
        return (
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" id={id} checked={value ? value : false} onChange={this.onChange}
                        className={classnames({ 'is-invalid': invalid })} />
                    {label}
                </Label>
            </FormGroup>
        );
    }
}

export class FormTextArea extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        onChange && onChange(this.props.id, event.target.value);
    }

    render() {
        const { id, label, placeholder, value, invalid } = this.props;
        return (
            <FormGroup>
                <Label for={id}>{label}</Label>
                <Input type="textarea" rows="4" id={id} value={value ? value : ''} onChange={this.onChange}
                    className={classnames({ 'is-invalid': invalid })} placeholder={placeholder} />
            </FormGroup>
        );
    }
}

export class FormFileChooser extends Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { onChange } = this.props;
        onChange && onChange(this.props.id, event.target.files[0]);
    }

    render() {
        const { id, label, invalid } = this.props;
        return (
            <FormGroup>
                <Label for={id}>{label}</Label>
                <Input type="file" id={id} onChange={this.onChange}
                    className={classnames({ 'is-invalid': invalid })} />
            </FormGroup>
        );
    }
}

export class FormSubmitButton extends Component {
    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(event) {
        const { onSubmit } = this.props;
        event.preventDefault();
        onSubmit && onSubmit();
    }

    render() {
        const { label, submitting, className } = this.props;
        return (
            <Button type='submit' color='primary' disabled={submitting} onClick={this.onSubmit} className={className}>{label} <Spinner spinning={submitting} size='small' /></Button>
        );
    }
}

export const FormDeleteButton = ({ label, deleting, onDelete, className, disabled = false }) => (
    <Button type='button' color={'danger'} disabled={deleting || disabled } onClick={onDelete} className={className}>{label} <Spinner spinning={deleting} size='small' /></Button>
);

export const FormRestoreButton = ({ label, restoring, onRestore, className }) => (
    <Button type='button' color={'info'} disabled={restoring} onClick={onRestore} className={className}>{label}<Spinner spinning={restoring} size='small' /></Button>
)

export const FormLinkButton = ({ label, link, className, onClick }) => (
    <Link to={link} className={className}>
        <Button type="button" outline onClick={onClick}>
            {label}
        </Button>
    </Link>
);
