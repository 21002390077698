import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchFootprints} from '../../actions/footprints';

const FootprintListPage = () => (
    <SimpleListPage
        dataUrl={'footprints'}
        dataName='footprint'
        fetchData={fetchFootprints}
        columns={[
            {field: 'name', label: 'Name'},
            {field: 'shortName', label: 'Short name'},
            {field: 'title', label: 'Title'},
            {field: 'satelliteName', label: 'Satellite'},
            {field: 'hasGeoJson', label: 'GeoJson', type: 'bool'}
        ]}
        pageSize={100}
    />
);

export default FootprintListPage;