import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';
import {objListToMap} from '../../services/MapExtractor';

class TeleportForm extends FormBase {
    render() {
        const { footprints, constellations } = this.props;
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('name', {label: 'Name'})}</Col>
                        <Col sm={6}>{this.select('type', {'idirect_hub':'iDirect Hub', 'teleport': 'Teleport'}, {label: 'Teleport type', placeholder: 'No type'})}</Col>

                        <Col sm={6}><Row>
                            <Col sm={6}>{this.number('latitude', {label: 'Latitude'})}</Col>
                            <Col sm={6}>{this.number('longitude', {label: 'Longitude'})}</Col>
                            <Col sm={12}>{this.input('fleetmapLocation', {label: 'Fleetmap location', placeholder: 'latitude, longitude (example: 125, -58)'})}</Col>
                        </Row></Col>

                        <Col sm={6}>{this.textArea('description', {label: 'Description'})}</Col>
                        <Col sm={6}>{this.textArea('address', {label: 'Address', placeholder: 'Example:\nChâteau de Betzdorf\nL-6815 Betzdorf\nLuxembourg'})}</Col>
                        <Col sm={6}>{this.multiSelect('footprintIds', objListToMap(footprints, 'id', 'name') || [], {label: 'GEO footprints (hold CTRL to select multiple)'})}</Col>

                        <Col sm={6}><Row>
                            <Col sm={6}>{this.select('feature', {'ip': 'IP', 'video': 'Video', 'video_and_ip': 'Video and IP'}, {label: 'Teleport feature', placeholder: 'Please select...'})}</Col>
                            <Col sm={6}>{this.select('status', {'owned': 'Owned', 'third_party': 'Third party'}, {label: 'Status', placeholder: 'Please select...'})}</Col>
                        </Row></Col>

                        <Col sm={6}>{this.multiSelect('constellationIds', objListToMap(constellations, 'id', 'name') || [], {label: 'MEO compatibility (hold CTRL to unselect)'})}</Col>
                    </Row>
                )}
            </Form>
        );
    }
}

export default TeleportForm