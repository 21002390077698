import React, {Component} from 'react'
import {CommonListPage} from './CommonListPage';

export class SimpleListPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.props.fetchData()
            .then(data => this.setState({data: data, dataSize: data.length}))
            .catch(error => this.setState({error: error}))
            .then(() => this.setState({loading: false}));
    }

    render() {
        return <CommonListPage {...this.props} {...this.state} />
    }
}