import React, { Component } from 'react';
import {
    FormCheckBox,
    FormDate,
    FormFileChooser,
    FormInput,
    FormLinkButton,
    FormMultiSelect,
    FormSelect,
    FormTextArea,
    FormListOrderer,
    FormMultiSelectDualList
} from "../../components/shared/FormComponents"
import { Card, CardText, CardTitle } from 'reactstrap';

export class FormBase extends Component {

    getValue(field) {
        const { data } = this.props;
        return data ? data[field] : undefined;
    }

    isInvalid(field) {
        const { formErrors } = this.props;
        return formErrors ? formErrors.includes(field) : false;
    }

        input(field, { label, placeholder, type = 'text', helpText = null, min, max, additionalData }) {
        return (
            <FormInput id={field} type={type} label={label} placeholder={placeholder} min={min} max={max} helpText={helpText} value={this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} additionalData={additionalData} />
        );
    }

    number(field, { label, placeholder, helpText, min, max }) {
        return this.input(field, { label: label, placeholder: placeholder, type: 'number', helpText: helpText, min: min, max: max });
    }

    password(field, { label, placeholder, helpText }) {
        return this.input(field, { label: label, placeholder: placeholder, type: 'password', helpText: helpText });
    }

    date(field, { label, placeholder, additionalData, type }) {
        return (
            <FormDate id={field} label={label} placeholder={placeholder} value={this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} additionalData={additionalData} type={type} />
        );
    }

    select(field, range, { label, placeholder, useIndexAsValue = true, disabled = false, value = null, additionalData, withReload = false }) {
        return (
            <FormSelect id={field} label={label} placeholder={placeholder} disabled={disabled || this.props.reloading} allowedValues={range} value={value ? value : this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} useIndexAsValue={useIndexAsValue} additionalData={additionalData} onReloading={this.props.onReloading} withReload={withReload} />
        );
    }

    multiSelect(field, range, { label }) {
        return (
            <FormMultiSelect id={field} label={label} allowedValues={range} value={this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} />
        );
    }

    multiSelectDualList(field, range, { label, withGrouping = false }) {
        return (
            <FormMultiSelectDualList id={field} label={label} allowedValues={range} withGrouping={withGrouping} value={this.formatForMultipleSelectList(this.getValue(field))} invalid={this.isInvalid(field)} onChange={this.props.onChange} />
        );
    }

    checkBox(field, { label }) {
        return (
            <FormCheckBox id={field} label={label} value={this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} />
        );
    }

    textArea(field, { label, placeholder }) {
        return (
            <FormTextArea id={field} label={label} placeholder={placeholder} value={this.getValue(field)} invalid={this.isInvalid(field)} onChange={this.props.onChange} />
        );
    }

    fileChooser(field, { label }) {
        return (
            <FormFileChooser id={field} label={label} invalid={this.isInvalid(field)} onChange={this.props.onChange} />
        );
    }

    formListOrderer(field, range, { label }) {
        return (
            <FormListOrderer id={field} label={label} allowedValues={range} value={this.getValue(field)} onChange={this.props.onChange} />
        );
    }

    linkButton(link, { label }) {
        return (
            <FormLinkButton label={label} link={link} />
        );
    }

    formCard(title, body) {
        return (
            <Card body className='mb-4'>
                <CardTitle>{title}</CardTitle>
                <CardText tag="div">
                    {body}
                </CardText>
            </Card>
        )
    }

    formatForMultipleSelectList(value) {
        return value;
    }
}