import axios from 'axios';

export function fetchConstellations() {
    return axios.get(`/webservice/backoffice/constellations`).then(response => response.data);
}

export function fetchConstellation(id) {
    return axios.get(`/webservice/backoffice/constellations/${id}`).then(response => response.data);
}

export function createConstellation(formData) {
    return axios.post(`/webservice/backoffice/constellations`, formData);
}

export function updateConstellation(id, formData) {
    return axios.post(`/webservice/backoffice/constellations/${id}`, formData);
}

export function deleteConstellation(id) {
    return axios.delete(`/webservice/backoffice/constellations/${id}`);
}