import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchBands} from '../../actions/bands';

const BandListPage = () => (
    <SimpleListPage
        dataUrl={'bands'}
        dataName='band'
        fetchData={fetchBands}
        columns={[
            {field: 'name', label: 'Name'},
            {field: 'satelliteName', label: 'Satellite'}
        ]}
        pageSize={100}
    />
);

export default BandListPage;