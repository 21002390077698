import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchGeoJsons} from '../../actions/geoJsons';

const GeoJsonListPage = () => (
    <SimpleListPage dataUrl={'geo_jsons'} dataName='geo-json' fetchData={fetchGeoJsons} columns={[
        {field: 'name', label: 'Name'}
    ]} />
);

export default GeoJsonListPage;