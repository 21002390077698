import axios from 'axios';
import Qs from 'qs'

export function fetchChannelsPage(pageSize, pageNumber, sorted, filtered) {
    const filterValues = {};
    filtered && filtered.forEach( f => filterValues[f.id] = f.value );
    return axios.get(`/webservice/backoffice/channels`, {
        params: {
            size: pageSize,
            page: pageNumber,
            sort: sorted && sorted.map(s => `${s.id},${s.desc ? 'desc' : 'asc'}`),
            ...filterValues
        },
        paramsSerializer: function(params) {
            return Qs.stringify(params, {indices: false})
        }
    }).then(response => response.data);
}

export function fetchChannel(id) {
    return axios.get(`/webservice/backoffice/channels/${id}`).then(response => response.data);
}