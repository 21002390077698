import * as React from 'react';
import {Component} from 'react';
import {Alert, Modal, ModalBody, ModalFooter} from 'reactstrap';
import Spinner from '../../components/shared/Spinner';
import {toast} from 'react-toastify';

import {
    FormLinkButton,
    FormSubmitButton,
    FormDeleteButton
} from "../../components/shared/FormComponents"

export default class CommonEditPage extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            reloading: false,
            submitting: false,
            showDeletionDialog: false,
            data: {},
            errors: [],
            defaultData: this.props.mainDataInfo.default,
            formErrors: []
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onEditionSubmit = this.onEditionSubmit.bind(this);
        this.onRestoreSubmit = this.onRestoreSubmit.bind(this);
        this.onCreationSubmit = this.onCreationSubmit.bind(this);
        this.onDeletionSubmit = this.onDeletionSubmit.bind(this);
        this.onDeletionConfirm = this.onDeletionConfirm.bind(this);
        this.onReloading = this.onReloading.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        const allPromises = this.loadSecDataInfo();
        Promise.all(allPromises)
            .then(() => this.setState({loading: false}));
    }

    loadSecDataInfo() {
        const id = this.props.match.params.id;

        const {mainDataInfo, secDataInfos} = this.props;
        const innerSecDataInfos = secDataInfos ? secDataInfos : [];

        const allPromises = innerSecDataInfos.map(info => this.thenCatch(info.promise(id), info));
        if(this.state.loading) {
            if(id) {
                allPromises.push(this.thenCatch(mainDataInfo.promise(id), mainDataInfo));
            } else {
                this.setState({[mainDataInfo.name]: mainDataInfo.default});
            }
        }

        return allPromises;
    }

    thenCatch(promise, info) {
        return promise
            .then((result) => {
                this.setState({[info.name]: result});
            })
            .catch(() => this.setState((oldState) => {
                return {errors: [...oldState.errors, new Error(`Failed to retrieve ${info.title}`)]};
            }));
    }

    render() {
        const {data, errors, loading, submitting, showDeletionDialog, formErrors} = this.state;
        const {formComponent, mainDataInfo} = this.props;
        const id = this.props.match.params.id;

        const onSubmit = id ? () => this.onEditionSubmit(id, data) : () => this.onCreationSubmit(data);
        const onDeleteQuery = id ? () => this.onDeletionSubmit(id) : undefined;
        const onDeleteConfirm = id ? () => this.onDeletionConfirm(id) : undefined;
        const title = id ? `Editing ${mainDataInfo.title}` : (mainDataInfo.duplicate ? `Duplicate ${mainDataInfo.title}` : `New ${mainDataInfo.title}`);
        const component = () => formComponent({
            ...this.state,
            onChange: this.onFormChange,
            onReloading: this.onReloading,
            onRestoreSubmit: this.onRestoreSubmit,
            formErrors: formErrors
        });
        const deletionMsg = `Confirm ${mainDataInfo.title} deletion`;

        return (
            <div className="mb-3">
                <ErrorFields errors={errors}/>
                <Data loading={loading} submitting={submitting} title={title} cancelPath={mainDataInfo.rootPath}
                      component={component} onSubmit={onSubmit} onDeleteQuery={onDeleteQuery} onCancel={this.onCancel} deleted={data["deleted"] ? data["deleted"] : false}/>
                <Loading loading={loading}/>
                <DeletionDialog message={deletionMsg} isOpen={showDeletionDialog}
                                onClose={() => this.setState({showDeletionDialog: false})} onConfirm={onDeleteConfirm}/>
            </div>
        )
    }

    onFormChange(field, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [field]: value
            }
        }));
    }


    onEditionSubmit(id, data) {
        const {buildFormData, updateCallback, mainDataInfo} = this.props;
        const formData = buildFormData ? buildFormData(data) : defaultBuildFormData(data);
        return this.processOperation(() => updateCallback(id, formData), `The ${mainDataInfo.title} has been updated successfully`);
    }

    onRestoreSubmit(id) {
        const {mainDataInfo, restoreCallback} = this.props;
        return this.processOperation(() => restoreCallback(id), `The ${mainDataInfo.title} has been successfully restored`);
    }

    onCreationSubmit(data) {
        const {buildFormData, createCallback, mainDataInfo} = this.props;
        const formData = buildFormData ? buildFormData(data) : defaultBuildFormData(data);
        return this.processOperation(() => createCallback(formData), `The ${mainDataInfo.title} has been created successfully`);
    }

    onDeletionSubmit(id) {
        this.setState({showDeletionDialog: true});
    }

    onDeletionConfirm(id) {
        const {deleteCallback, mainDataInfo} = this.props;
        return this.processOperation(() => deleteCallback(id), `The ${mainDataInfo.title} has been deleted successfully`);
    }

    onCancel() {
        this.closeWindow();
    }

    onReloading() {
        this.setState({reloading: true})
        toast.info("Reloading content ...");
        const allPromises = this.loadSecDataInfo();
        Promise.all(allPromises)
            .then(() => {
                this.setState({reloading: false})
                toast.info("Content has been reloaded.")
            });
    }

    closeWindow() {
        const close = new URLSearchParams(this.props.location.search).get('close');
        if (close) {
            window.close();
        }
    }

    processOperation(operation, successMsg) {
        const {history} = this.props;
        this.setState({formErrors: [], submitting: true}, () => {
            return operation()
                .then(() => {
                    toast.success(successMsg);
                    this.closeWindow();
                })
                .then(() => history.goBack())
                .catch(error => {
                    console.error('An error occurred', error);
                    if (error.response) {
                        if (error.response.data.errors) {
                            toast.error("The form is invalid");
                            this.setState({formErrors: error.response.data.errors.map(e => e.field)});
                        } else {
                            toast.error(error.response.data.error + ' : ' + error.response.data.message);
                        }
                    } else {
                        toast.error(error);
                    }
                })
                .then(this.setState({submitting: false}));
        });
    }
}

function defaultBuildFormData(data) {
    const formData = new FormData();
    Object.keys(data)
        .filter(key => isDefined(data[key]))
        .forEach(key => formData.append(key, data[key]));
    return formData;
}

function isDefined(value) {
    return !!value || value === 0;
}

const ErrorFields = ({errors}) => (
    <div>
        {!!errors && errors.map((error, index) => (<Alert color='danger' key={index}>{error.message}</Alert>))}
    </div>
)

const Data = ({title, loading, submitting, cancelPath, component, onSubmit, onDeleteQuery, onCancel, deleted = false}) => (
    <div>
        {!loading &&
        <div>
            <h1>{title}</h1>
            {component()}
            <FormLinkButton label='Cancel' link={cancelPath} className="mr-2" onClick={onCancel}/>
            {onSubmit && <FormSubmitButton label='Save' onSubmit={onSubmit} submitting={submitting} className="mr-2"/>}
            {onDeleteQuery && <FormDeleteButton label='Delete' onDelete={onDeleteQuery} deleting={submitting} disabled={deleted}/>}
        </div>
        }
    </div>
)

const Loading = ({loading}) => (
    <div className="w-100 text-center">
        <Spinner spinning={loading} size='large'/>
    </div>
)

const DeletionDialog = ({message, isOpen, onClose, onConfirm}) => (
    <Modal isOpen={isOpen}>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
            <FormSubmitButton label='Cancel' onSubmit={onClose}/>
            <FormDeleteButton label='Confirm' onDelete={onConfirm}/>
        </ModalFooter>
    </Modal>
)