import * as React from 'react';
import * as classnames from 'classnames';

const sizes = {
    'small' : 'fa-1x',
    'medium' : 'fa-3x',
    'large' : 'fa-5x'
};

const Spinner = ({spinning, size}) => (
    spinning ? (
        <i className={classnames('fa', 'fa-cog', 'fa-spin', 'fa-fwml-auto', {[sizes[size]]: true})}>
            <span className="sr-only">Loading...</span>
        </i>
    ) : null
);

export default Spinner;