import React from 'react'
import {PaginatedListPage} from '../shared/PaginatedListPage';
import {fetchChannelsPage} from '../../actions/channels';

const ChannelListPage = (props) => (
    <PaginatedListPage
        {...props}
        dataUrl={'channels'}
        dataName='channel'
        fetchData={fetchChannelsPage}
        readOnly={true}
        columns={[
            {field: 'name', label: 'Name'},
            {field: 'updatedAt', label: 'Updated', type: 'dateTime'}
        ]}
        pageSize={100}
    />
);

export default ChannelListPage;