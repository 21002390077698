const AppConfig = {
    development: {
        API_BASE_URL: '//localhost:8080',
        CLIENT_ID: 'backoffice',
        CLIENT_SECRET: 'foobar'
    },
    staging: {
        API_BASE_URL: '//staging.sat.ses.com',
        CLIENT_ID: 'backoffice',
        CLIENT_SECRET: '2yXQzpTo3XcBY0d7'
    },
    production: {
        API_BASE_URL: '//sat.ses.com',
        CLIENT_ID: 'backoffice',
        CLIENT_SECRET: 'DGtVhjUcf26FEhQs'
    }
};

const getAppConfig = () => {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;

    if (env === "prod") {
      return AppConfig.production;
    } else if (env === "staging") {
      return AppConfig.staging;
    }
    return AppConfig.development;
};

export default getAppConfig;