import * as React from 'react';
import CommonEditPage from '../shared/CommonEditPage';
import moment from 'moment/moment';
import {createConstellation, deleteConstellation, fetchConstellation, updateConstellation} from "../../actions/constellations";

import ConstellationForm from "../../components/constellations/ConstellationForm";
import {fetchSatellites} from "../../actions/satellites";

const INITIAL_DATA = {
    name: '',
    launchDate: null
};

const ConstellationEditPage = (props) => (
    <CommonEditPage
        {...props}

        mainDataInfo={{
            name: 'data',
            title: 'constellation',
            rootPath: '/constellations',
            default: INITIAL_DATA,
            promise: (id) => fetchConstellation(id).then(satellite => ({
                ...satellite,
                launchDate: satellite.launchDate ? moment(satellite.launchDate).toDate() : null
            }))
        }}
        secDataInfos={[
            { name: 'satellites', title: 'satellites', promise: () => fetchSatellites({ type: "MEO" }) },
        ]}
        formComponent={(props) => (<ConstellationForm {...props} />)}
        updateCallback={updateConstellation}
        createCallback={createConstellation}
        deleteCallback={deleteConstellation}
        buildFormData={buildFormData}
    />
)

function buildFormData(data) {
    const formData = new FormData();
    Object.keys(data)
        .forEach(key => formData.append(key, data[key] === null ? '' : data[key]));
    return formData;
}

export default ConstellationEditPage;