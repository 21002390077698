import axios from 'axios';

export function fetchPops() {
    return axios.get(`/webservice/backoffice/pops`).then(response => response.data);
}

export function fetchPop(id) {
    return axios.get(`/webservice/backoffice/pops/${id}`).then(response => response.data);
}

export function createPop(formData) {
    return axios.post(`/webservice/backoffice/pops`, formData);
}

export function updatePop(id, formData) {
    return axios.post(`/webservice/backoffice/pops/${id}`, formData);
}

export function deletePop(id) {
    return axios.delete(`/webservice/backoffice/pops/${id}`);
}
