import * as React from 'react'
import FootprintForm from '../../components/footprints/FootprintForm';
import {fetchFootprint, updateFootprint, createFootprint, deleteFootprint} from '../../actions/footprints';
import {fetchSatellites} from '../../actions/satellites';
import {fetchGeoJsons} from '../../actions/geoJsons';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const FootprintEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'footprint',
            rootPath: '/footprints',
            default: INITIAL_DATA,
            promise: (id) => fetchFootprint(id)    
        }}
        secDataInfos={[
            { name: 'satellites', title: 'satellites', promise: () => fetchSatellites() },
            { name: 'geoJsons', title: 'geo-jsons', promise: () => fetchGeoJsons() }
        ]}
        formComponent={(props) => ( <FootprintForm {...props} /> )}
        updateCallback={updateFootprint} 
        createCallback={createFootprint}
        deleteCallback={deleteFootprint}
        buildFormData={buildFormData}
    />
)
    
function buildFormData(data) {
    const formData = new FormData();
    Object.keys(data)
        .filter(key => data[key])
        .filter(key => key !== 'geoJson')
        .forEach(key => formData.append(key, data[key]));
    return formData;
}

export default FootprintEditPage;
