import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';

export default class VehicleForm extends FormBase {
    render() {
        const { data } = this.props;
        return (
            <Form noValidate>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('name', {label: 'Name'})}</Col>
                        <Col sm={6}>{this.input('title', {label: 'Title'})}</Col>
                        <Col sm={12}>{this.input('homepageUrl', {label: 'Homepage url'})}</Col>
                    </Row>
                )}

                {this.formCard("Logo/Image",
                    <div>
                        { data && data.logoImage && (<img src={data.logoImage} alt='vehicle'/>)}
                        {this.fileChooser('image', {label: 'Upload new Logo/Image'})}
                    </div>
                )}
            </Form>
        );
    }
}