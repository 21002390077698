import React, {Component} from 'react';
import {DatePicker} from 'react-widgets';
import './OpenOnFocusDatePicker.css';

export default class OpenOnFocusDatePicker extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: props.open ? props.open : false
        };
    }

    handleInputClick(callback, event) {
        if (event.target.tagName === 'INPUT') {
            this.setState({open: true});
        }
        return callback && callback(event);
    }

    handleToggle(callback) {
        this.setState({open: !this.state.open});
        return callback && callback();
    }

    render() {
        return (
            <DatePicker
                {...this.props}
                onClick={this.handleInputClick.bind(this, this.props.onClick)}
                onToggle={this.handleToggle.bind(this, this.props.onToggle)}
                onChange={this.props.onChange}
                open={this.state.open}
                format={this.props.format}
                placeholder={this.props.placeholder.toLowerCase()}
            />
        );
    }
}
