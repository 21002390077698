import axios from 'axios';

export function fetchOrbitalPositions() {
    return axios.get(`/webservice/backoffice/orbital_positions`).then(response => response.data);
}

export function fetchOrbitalPosition(id) {
    return axios.get(`/webservice/backoffice/orbital_positions/${id}`).then(response => response.data);
}

export function createOrbitalPosition(formData) {
    return axios.post(`/webservice/backoffice/orbital_positions`, formData);
}

export function updateOrbitalPosition(id, formData) {
    return axios.post(`/webservice/backoffice/orbital_positions/${id}`, formData);
}

export function deleteOrbitalPosition(id) {
    return axios.delete(`/webservice/backoffice/orbital_positions/${id}`);
}