import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchManufacturers} from '../../actions/manufacturers';

const ManufacturerListPage = () => (
    <SimpleListPage dataUrl={'manufacturers'} dataName='manufacturer' fetchData={fetchManufacturers} columns={[
        {field: 'name', label: 'Name'},
        {field: 'title', label: 'Title'}
    ]} />
);

export default ManufacturerListPage;