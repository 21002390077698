import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchTeleports} from '../../actions/teleports';

const TeleportListPage = () => (
    <SimpleListPage dataUrl={'teleports'} dataName='teleport' fetchData={fetchTeleports} columns={[
        {field: 'name', label: 'Name'},
        {field: 'description', label: 'Description', type: 'html'},
        {field: 'longitude', label: 'Longitude'},
        {field: 'latitude', label: 'Latitude'},
        {field: 'fleetmapLocation', label: 'Position on fleetmap'}
    ]} />
);

export default TeleportListPage;
