import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchVehicles} from '../../actions/vehicles';

const VehicleListPage = () => (
    <SimpleListPage dataUrl={'vehicles'} dataName='vehicle' fetchData={fetchVehicles} columns={[
        {field: 'name', label: 'Name'},
        {field: 'title', label: 'Title'}
    ]} />
);

export default VehicleListPage;
