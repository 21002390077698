import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'react-widgets/dist/css/react-widgets.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-table/react-table.css'
import "@kenshooui/react-multi-select/dist/style.css"
import { unregister } from './registerServiceWorker';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import Header from './components/header/Header';
import { Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import SatelliteListPage from './pages/satellites/SatelliteListPage';
import BandListPage from './pages/bands/BandListPage';
import BandEditPage from './pages/bands/BandEditPage';
import SatelliteEditPage from './pages/satellites/SatelliteEditPage';
import SatelliteDuplicatePage from './pages/satellites/SatelliteDuplicatePage';
import VehicleListPage from './pages/vehicles/VehicleListPage';
import VehicleEditPage from './pages/vehicles/VehicleEditPage';
import ManufacturerListPage from './pages/manufacturers/ManufacturerListPage';
import ManufacturerEditPage from './pages/manufacturers/ManufacturerEditPage';
import FootprintListPage from './pages/footprints/FootprintListPage';
import FootprintEditPage from './pages/footprints/FootprintEditPage';
import GeoJsonListPage from './pages/geo_jsons/GeoJsonListPage';
import GeoJsonEditPage from './pages/geo_jsons/GeoJsonEditPage';
import OrbitalPositionListPage from './pages/orbital_positions/OrbitalPositionListPage';
import authService from './services/AuthService';
import LoginPage from './pages/login/LoginPage';
import TeleportListPage from './pages/teleports/TeleportListPage';
import TeleportEditPage from './pages/teleports/TeleportEditPage';
import history from './history';
import OrbitalPositionEditPage from './pages/orbital_positions/OrbitalPositionEditPage';
import getAppConfig from './config';
import axios from 'axios';
import ChannelListPage from './pages/channels/ChannelListPage';
import ChannelPage from './pages/channels/ChannelPage';
import { PageNotFound } from './pages/errors/PageNotFound';
import LaunchListPage from "./pages/launches/LaunchListPage";
import LaunchEditPage from "./pages/launches/LaunchEditPage";
import ConstellationListPage from "./pages/constellations/ConstellationListPage";
import ConstellationEditPage from "./pages/constellations/ConstellationEditPage";
import PopListPage from "./pages/pops/PopListPage";
import PopEditPage from "./pages/pops/PopEditPage";

const API_BASE_URL = getAppConfig().API_BASE_URL;
axios.defaults.baseURL = API_BASE_URL;


Moment.locale('en');
momentLocalizer();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        if (authService.isLoggedIn() || authService.canRefreshToken()) {
            return (<Component {...props} />)
        }
        // make sure the tokens are cleared
        authService.logout();
        return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
    }} />
);

class Root extends Component {
    constructor() {
        super();
        this.state = {
            user: authService.getUser()
        };
        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    onLogin() {
        this.setState({
            user: authService.getUser()
        });
    }

    onLogout() {
        authService.logout();
        this.setState({
            user: null
        });
    }

    render() {
        return (
            <Router history={history}>
                <div>
                    <Route render={props => <Header user={this.state.user || null} onLogout={this.onLogout} {...props} />} />
                    <main>
                        <Container>
                            <Switch>
                                <Route exact path='/login' render={props => (<LoginPage onLogin={this.onLogin} {...props} />)} />
                                <PrivateRoute exact path='/' component={SatelliteListPage} />
                                <PrivateRoute exact path='/satellites' component={SatelliteListPage} />
                                <PrivateRoute exact path='/satellites/new' component={SatelliteEditPage} />
                                <PrivateRoute exact path='/satellites/duplicate' component={SatelliteDuplicatePage} />
                                <PrivateRoute path='/satellites/:id' component={SatelliteEditPage} />
                                <PrivateRoute exact path='/vehicles' component={VehicleListPage} />
                                <PrivateRoute exact path='/vehicles/new' component={VehicleEditPage} />
                                <PrivateRoute path='/vehicles/:id' component={VehicleEditPage} />
                                <PrivateRoute exact path='/manufacturers' component={ManufacturerListPage} />
                                <PrivateRoute exact path='/manufacturers/new' component={ManufacturerEditPage} />
                                <PrivateRoute path='/manufacturers/:id' component={ManufacturerEditPage} />
                                <PrivateRoute exact path='/footprints' component={FootprintListPage} />
                                <PrivateRoute exact path='/footprints/new' component={FootprintEditPage} />
                                <PrivateRoute path='/footprints/:id' component={FootprintEditPage} />
                                <PrivateRoute exact path='/geo_jsons' component={GeoJsonListPage} />
                                <PrivateRoute exact path='/geo_jsons/new' component={GeoJsonEditPage} />
                                <PrivateRoute path='/geo_jsons/:id' component={GeoJsonEditPage} />
                                <PrivateRoute exact path='/bands' component={BandListPage} />
                                <PrivateRoute exact path='/bands/new' component={BandEditPage} />
                                <PrivateRoute path='/bands/:id' component={BandEditPage} />
                                <PrivateRoute exact path='/orbital_positions' component={OrbitalPositionListPage} />
                                <PrivateRoute exact path='/orbital_positions/new' component={OrbitalPositionEditPage} />
                                <PrivateRoute path='/orbital_positions/:id' component={OrbitalPositionEditPage} />
                                <PrivateRoute exact path='/teleports' component={TeleportListPage} />
                                <PrivateRoute exact path='/teleports/new' component={TeleportEditPage} />
                                <PrivateRoute path='/teleports/:id' component={TeleportEditPage} />
                                <PrivateRoute exact path='/pops' component={PopListPage} />
                                <PrivateRoute exact path='/pops/new' component={PopEditPage} />
                                <PrivateRoute path='/pops/:id' component={PopEditPage} />
                                <PrivateRoute exact path='/channels' component={ChannelListPage} />
                                <PrivateRoute path='/channels/:id' component={ChannelPage} />
                                <PrivateRoute exact path='/launches' component={LaunchListPage} />
                                <PrivateRoute exact path='/launches/new' component={LaunchEditPage} />
                                <PrivateRoute path='/launches/:id' component={LaunchEditPage} />
                                <PrivateRoute exact path='/constellations' component={ConstellationListPage} />
                                <PrivateRoute exact path='/constellations/new' component={ConstellationEditPage} />
                                <PrivateRoute path='/constellations/:id' component={ConstellationEditPage} />
                                <Route component={PageNotFound} />
                            </Switch>
                        </Container>
                    </main>
                    <ToastContainer autoClose={5000} hideProgressBar={true} />
                </div>
            </Router>
        )
    }
}

ReactDOM.render((
    <Root />
), document.getElementById('root'));

unregister();
