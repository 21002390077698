import React from 'react'
import { CommonListPage } from '../shared/CommonListPage';
import { fetchSatellites } from '../../actions/satellites';
import { CustomInput, Label } from 'reactstrap'

const SwitchDeletedInput = ({onChange}) => (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <CustomInput type="switch" name="switchDeleted" id="switchDeletedInput" onClick={(evt) => onChange(evt.target.checked)} />
        <Label for="switchDeleted">Show deleted satellites</Label>
    </div>
);

class SatelliteListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.fetchData(this.state.deleted);
    }

    fetchData(deleted) {
        this.setState({loading: true});
        fetchSatellites({deleted: deleted})
            .then(data => this.setState({data: data, dataSize: data.length}))
            .catch(error => this.setState({error: error}))
            .then(() => this.setState({loading: false}));
    }

    render() {
        return <CommonListPage
                dataUrl={'satellites'}
                dataName='satellite'
                columns={[
                    { field: 'name', label: 'Name', transform: (cell) => <span>{cell.value} { cell.original && cell.original.deleted ? <sup style={{color: 'rgba(200, 0, 0, 0.5)'}}>deleted</sup> : null }</span> },
                    { field: 'title', label: 'Title' },
                    { field: 'type', label: 'Type' },
                    { field: 'commercialized', label: 'Commercialized', type: 'bool' }
                ]}
                defaultSorted={[
                    { id: 'name' }
                ]}
                listHeader={<SwitchDeletedInput onChange={(value) => this.fetchData(value)}/>}
                pageSize={100}
                duplicate={true}
                {...this.state}
            />
    }
}

export default SatelliteListPage;
