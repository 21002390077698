import React from 'react'
import {SimpleListPage} from '../shared/SimpleListPage';
import {fetchPops} from '../../actions/pops';

const PopListPage = () => (
    <SimpleListPage dataUrl={'pops'} dataName='pop' fetchData={fetchPops} columns={[
        {field: 'name', label: 'Name'},
        {field: 'state', label: 'State/country'},
        {field: 'city', label: 'City'},
        {field: 'colo', label: 'Colo name'},
    ]} />
);

export default PopListPage;
