import axios from 'axios';

export function fetchGeoJsons() {
    return axios.get(`/webservice/backoffice/geo_jsons`).then(response => response.data);
}

export function fetchGeoJson(id) {
    return axios.get(`/webservice/backoffice/geo_jsons/${id}`).then(response => response.data);
}

export function createGeoJson(formData) {
    return axios.post(`/webservice/backoffice/geo_jsons`, formData);
}

export function updateGeoJson(id, formData) {
    return axios.post(`/webservice/backoffice/geo_jsons/${id}`, formData);
}

export function deleteGeoJson(id) {
    return axios.delete(`/webservice/backoffice/geo_jsons/${id}`);
}
