import * as React from 'react'
import BandForm from '../../components/bands/BandForm';
import {fetchSatellites} from '../../actions/satellites';
import {fetchBand, updateBand, createBand, deleteBand} from '../../actions/bands';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const BandEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'band',
            rootPath: '/bands',
            default: INITIAL_DATA,
            promise: (id) => fetchBand(id)    
        }}
        secDataInfos={[
            { name: 'satellites', title: 'satellites', promise: () => fetchSatellites() }
        ]}
        formComponent={(props) => ( <BandForm {...props} /> )}
        updateCallback={updateBand} 
        createCallback={createBand}   
        deleteCallback={deleteBand} 
    />
)

export default BandEditPage;