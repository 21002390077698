import React from 'react'
import { fetchSatellites, duplicateSatellite } from '../../actions/satellites';
import SatelliteDuplicate from '../../components/satellites/SatelliteDuplicate';
import CommonEditPage from '../shared/CommonEditPage';

const SatelliteDuplicatePage = (props) => (
    <CommonEditPage
        {...props}

        mainDataInfo={{
            name: 'data',
            title: 'satellite',
            duplicate: true,
            rootPath: '/satellites',
            default: { satelliteId: null, count: 1 }
        }}
        secDataInfos={[
            { name: 'satellites', title: 'satellites', promise: () => fetchSatellites() }
        ]}
        formComponent={(props) => (<SatelliteDuplicate {...props} />)}
        createCallback={duplicateSatellite}
    />
)

export default SatelliteDuplicatePage;
