import axios from 'axios';

export function fetchTeleports() {
    return axios.get(`/webservice/backoffice/teleports`).then(response => response.data);
}

export function fetchTeleport(id) {
    return axios.get(`/webservice/backoffice/teleports/${id}`).then(response => response.data);
}

export function createTeleport(formData) {
    return axios.post(`/webservice/backoffice/teleports`, formData);
}

export function updateTeleport(id, formData) {
    return axios.post(`/webservice/backoffice/teleports/${id}`, formData);
}

export function deleteTeleport(id) {
    return axios.delete(`/webservice/backoffice/teleports/${id}`);
}
