import * as React from 'react';

import ReactTable from 'react-table';
import Spinner from '../../components/shared/Spinner';

import { buildFromData } from './react-table/columnsBuilder';

import './react-table/ses.css';

export const CommonTable = ({
    data,
    dataUrl,
    columns,
    readOnly,
    loading,
    nbPages,
    onFetchData,
    defaultSorted,
    pageSize,
    filterMethod
}) => {
    const formattedColumns = buildFromData(dataUrl, columns, readOnly);

    return (
        <ReactTable manual={isManual(onFetchData)} onFetchData={getFetchDataCallback(onFetchData)}
            data={data} columns={formattedColumns}
            pages={nbPages}
            loading={loading}
            loadingText={<Spinner spinning={true} size='large' />}
            className="-striped -highlight"
            minRows={5}
            defaultPageSize={pageSize ? pageSize : 10}
            defaultSorted={defaultSorted ? defaultSorted : []}
        />
    );
}

function isManual(onFetchData) {
    return !!onFetchData;
}

function getFetchDataCallback(onFetchData) {
    return onFetchData ? onFetchData : () => null;
}

