import * as React from 'react';
import { FormBase } from '../shared/FormBase';
import { Col, Form, Row, Alert } from 'reactstrap';
import { objListToMap } from '../../services/MapExtractor';

class LaunchForm extends FormBase {
    constructor(props) {
        super(props);
        const { satellites } = this.props;
        this.state = {
            partialDate: this.isPartial(),
            satellites: this.formatForMultipleSelectList(satellites)
        }

    }

    isPartial() {
        const launchDate = this.getValue("launchDate");
        const partialLaunchDate = this.getValue("partialLaunchDate");
        return !((!launchDate && !partialLaunchDate) || launchDate);
    }

    render() {
        const { vehicles, formErrors } = this.props;
        const dateOptions = { label: this.state.partialDate ? "Approximative launch date" : "Launch date", additionalData: this.changeType(), type: this.state.partialDate ? "partial" : "complete" };
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('name', { label: 'Name' })}</Col>
                        <Col sm={6}>{this.state.partialDate ? this.date('launchDate', dateOptions) : this.date('launchDate', dateOptions)}</Col>
                        <Col sm={3}>{this.checkBox('visible', { label: 'Display on launch page ?' })}</Col>
                        <Col sm={3}>{this.checkBox('isFuture', { label: 'Is it a future launch ?' })}</Col>
                        {this.state.partialDate ? <Col sm={6}>{this.input("partialLaunchDate", { label: "Partial Launch Date", placeholder: "Incomplete date like 'Q1 2021' ..." })}</Col> : null}
                    </Row>
                )}
                {this.formCard("Satellite information",
                    <Row>
                        <Col sm={12}>{this.select('vehicleId', objListToMap(vehicles, 'id', 'title'), { label: 'Vehicle', placeholder: 'Please select...' })}</Col>
                        <Col sm={12}>{this.multiSelectDualList('satellites', this.state.satellites, { label: 'Satellites', withGrouping: true })}</Col>
                        {formErrors.includes("satellites") ?
                            <Col sm={12}><Alert color="warning">A launch can only be composed of either one GEO Satellite, or multiple MEO Satellites.</Alert></Col>
                            : null
                        }
                    </Row>
                )}
            </Form>
        );
    }

    changeType() {
        return <button type="button" className={"button-link"} onClick={() => this.setState({ partialDate: !this.state.partialDate })}>
            {this.state.partialDate ? "You know the exact date ?" : "You do not know the date ?"}
        </button>;
    }

    formatForMultipleSelectList(satellites) {
        if (satellites && Array.isArray(satellites))
            return satellites.map((s) => { return { id: s.id, label: s.name, group: s.constellationName ? s.constellationName : "No constellation" } });
        return [];
    }
}

export default LaunchForm