import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';
import {objListToMap} from '../../services/MapExtractor';

export default class BandForm extends FormBase {
    render() {
        const { satellites } = this.props;
        return (
            <Form noValidate>

                {this.formCard("General",
                    <Row>
                        <Col sm={4}>{this.select('name', {'c': 'C-band', 'ku': 'Ku-band', 'ka': 'Ka-band'}, {label: 'Name', placeholder: 'Please select...'})}</Col>
                        <Col sm={4}>{this.select('satelliteId', objListToMap(satellites, 'id', 'name'), {label: 'Satellite', placeholder: 'Please select...'})}</Col>
                        <Col sm={4}>{this.select('polarization', {'linear': 'Linear', 'dual linear': 'Dual linear', 'circular': 'Cirdular'}, {label: 'Polarization', placeholder: 'Please select...'})}</Col>

                        <Col sm={3}>{this.input('uplinkFrequencies', {label: 'Uplink frequencies'})}</Col>
                        <Col sm={3}>{this.input('downlinkFrequencies', {label: 'Downlink frequencies'})}</Col>
                        <Col sm={3}>{this.input('transponders', {label: 'Transponders'})}</Col>
                        <Col sm={3}>{this.input('hts', {label: 'Hts'})}</Col>
                    </Row>
                )}

                {this.formCard("Extra information", (this.textArea('description', {label: 'Description'})))}

                <div className='mb-4'>
                    {this.checkBox('deleted', {label: 'Deleted'})}
                </div>
            </Form>
        );
    }
}