import * as React from 'react'
import OrbitalPositionForm from '../../components/orbital_positions/OrbitalPositionForm';
import {fetchOrbitalPosition, updateOrbitalPosition, createOrbitalPosition, deleteOrbitalPosition} from '../../actions/orbitalPositions';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const OrbitalPositionEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'orbital position',
            rootPath: '/orbital_positions',
            default: INITIAL_DATA,
            promise: (id) => fetchOrbitalPosition(id)    
        }}
        formComponent={(props) => ( <OrbitalPositionForm {...props} /> )}
        updateCallback={updateOrbitalPosition} 
        createCallback={createOrbitalPosition}        
        deleteCallback={deleteOrbitalPosition}            
    />
)

export default OrbitalPositionEditPage;