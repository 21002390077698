import axios from 'axios';

export function fetchVehicles() {
    return axios.get(`/webservice/backoffice/vehicles`).then(response => response.data);
}

export function fetchVehicle(id) {
    return axios.get(`/webservice/backoffice/vehicles/${id}`).then(response => response.data);
}

export function createVehicle(formData) {
    return axios.post(`/webservice/backoffice/vehicles`, formData);
}

export function updateVehicle(id, formData) {
    return axios.post(`/webservice/backoffice/vehicles/${id}`, formData);
}

export function deleteVehicle(id) {
    return axios.delete(`/webservice/backoffice/vehicles/${id}`);
}