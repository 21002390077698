import * as React from 'react';
import CommonEditPage from '../shared/CommonEditPage';
import moment from 'moment/moment';
import {createLaunch, deleteLaunch, fetchLaunch, updateLaunch} from "../../actions/launches";
import { fetchVehicles } from '../../actions/vehicles';

import LaunchForm from "../../components/launches/LaunchForm";
import {fetchSatellites} from "../../actions/satellites";

const INITIAL_DATA = {
    name: '',
    launchDate: null
};

const LaunchEditPage = (props) => (
    <CommonEditPage
        {...props}

        mainDataInfo={{
            name: 'data',
            title: 'launch',
            rootPath: '/launches',
            default: INITIAL_DATA,
            promise: (id) => fetchLaunch(id).then(satellite => ({
                ...satellite,
                launchDate: satellite.launchDate ? moment(satellite.launchDate).toDate() : null
            }))
        }}
        secDataInfos={[
            { name: 'satellites', title: 'satellites', promise: (id) => fetchSatellites({forLaunch: id ? id : -1})},
            { name: 'vehicles', title: 'vehicles', promise: () => fetchVehicles() },
        ]}
        formComponent={(props) => (<LaunchForm {...props} />)}
        updateCallback={updateLaunch}
        createCallback={createLaunch}
        deleteCallback={deleteLaunch}
        buildFormData={buildFormData}
    />
)

function buildFormData(data) {
    const formData = new FormData();
    Object.keys(data)
        .filter(key => key !== "launchDate")
        .forEach(key => formData.append(key, data[key] === null ? '' : data[key]));

    formData.set('launchDate', data.launchDate ? data.launchDate.toISOString() : '');

    return formData;
}

export default LaunchEditPage;