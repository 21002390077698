import * as React from 'react';
import {Label, Input, Row, Col, Container} from 'reactstrap';

export function isFilterable(type) {
    switch(type) {
        default: return true;
    }
}

export function getFilterMethod(type) {
    switch(type) {
        case 'bool': return (filter, row) => filter.value === 'all' ? true : row[filter.id] === (filter.value === 'true');
        case 'simple-bool': return (filter, row) => filter.value === 'all' ? true : row[filter.id] === (filter.value === 'true');
        default: return (filter, row) => toValidString(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase());
    }
}

function toValidString(val) {
    return val ? '' + val : '';
}

export function getFilterRenderer(type) {
    switch(type) {
        case 'bool': return ({ filter, onChange }) => <BoolFilter filter={filter} onChange={onChange} />;
        case 'simple-bool': return ({ filter, onChange }) => <SimpleBoolFilter filter={filter} onChange={onChange} />;
        default: return ({ filter, onChange }) => <TextFilter filter={filter} onChange={onChange} />;
    }
}

const BoolFilter = ({ filter, onChange }) => (
    <Container>
        <Row>
            <BoolFilterCol filter={filter} value={'true'} checked={false} onChange={onChange} 
                icon={() => <i className="fa fa-check" style={{color:'Green'}} />} />
            <BoolFilterCol filter={filter} value={'all'} checked={true} onChange={onChange} 
                icon={() => <i className="fa fa-question" style={{color:'Grey'}} />} />
            <BoolFilterCol filter={filter} value={'false'} checked={false} onChange={onChange} 
                icon={() => <i className="fa fa-times" style={{color:'Red'}} />} />
        </Row>
    </Container>
);

const SimpleBoolFilter = ({ filter, onChange }) => (
    <Container>
        <Row>
            <BoolFilterCol filter={filter} value={'true'} checked={false} onChange={onChange} 
                icon={() => <i className="fa fa-check-square" />} />
            <BoolFilterCol filter={filter} value={'all'} checked={true} onChange={onChange} 
                icon={() => <i className="fa fa-minus-square" />} />
            <BoolFilterCol filter={filter} value={'false'} checked={false} onChange={onChange} 
                icon={() => <i className="fa fa-square" />} />
        </Row>
    </Container>
);

const BoolFilterCol = ({ filter, value, checked, onChange, icon }) => (
    <Col>
        <Label check>
            <Input type='radio' value={value} checked={filter ? filter.value === value : checked} onChange={e => onChange(e.target.value)} />
            {icon()}
        </Label>
    </Col>
);

const TextFilter = ({ filter, onChange }) => (
    <Input onChange={e => onChange(e.target.value)} value={filter ? filter.value : ''} />
);
