import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';

class ConstellationForm extends FormBase {
    constructor(props) {
        super(props);
        const { satellites } = this.props;
        this.state = {
            satellites: this.formatForMultipleSelectList(satellites)
        }

    }

    render() {
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('name', {label: 'Name'})}</Col>
                        <Col sm={12}>{this.textArea('description', {label: 'Description'})}</Col>
                    </Row>
                )}
                {this.formCard("Constellation satellites information", 
                    <Row>
                        <Col sm={12}>{this.multiSelectDualList('satellites', this.state.satellites, {label: 'MEO Satellites'})}</Col>
                    </Row>
                )}
            </Form>
        );
    }

    formatForMultipleSelectList(satellites) {
        if(satellites && Array.isArray(satellites))
            return satellites.map((s) => {return {id: s.id, label: s.name}});
        return satellites;
    }
}

export default ConstellationForm