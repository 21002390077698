import * as React from 'react';
import DOMPurify from 'dompurify'
import {formatDate} from '../../../services/DateFormatter';

export function getCellRenderer(type, transform) {
    return cell => {
        const value = transform ? transform(cell) : cell.value;
        switch(type) {
            case 'bool': return ( <BooleanCell value={value} /> );
            case 'simple-bool': return (<SimpleBooleanCell value={value} />);
            case 'html': return ( <HtmlCell value={value} /> );
            case 'dateTime': return ( <DateTimeCell value={value} /> );
            default: return ( <TextCell value={value} /> );
        }
    }
}

const BooleanCell = ({value}) => (
    <div className="w-100 text-center">{value ? (<i className="fa fa-check" style={{color:'Green'}} />) : (<i className="fa fa-times" style={{color:'Red'}} />)}</div>
)

const SimpleBooleanCell = ({value}) => (
    <div className="w-100 text-center">{value ? (<i className="fa fa-check-square" />) : "" }</div>
)

const HtmlCell = ({value}) => (
    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value)}} />
);

const TextCell = ({value}) => (
    <div>{value}</div>
);

const DateTimeCell = ({value}) => (
    <div>{formatDate(value)}</div>
);