import React from 'react'
import { SimpleListPage } from '../shared/SimpleListPage';
import { fetchLaunches } from '../../actions/launches';

const LaunchListPage = () => (
    <SimpleListPage
        dataUrl={'launches'}
        dataName='launch'
        fetchData={fetchLaunches}
        columns={[
            { field: 'name', label: 'Name' },
            { field: 'satellitesCount', label: 'Satellites Count' },
            { field: 'launchDate', label: 'Launch Date'},
            { field: 'partialLaunchDate', label: 'Partial Launch Date'},
            { field: 'visible', label: 'Visible', type: 'bool'}
        ]}
        defaultSorted={[
            { id: 'name' }
        ]}
        pageSize={100}
    />
);

export default LaunchListPage;
