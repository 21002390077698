
import * as React from 'react';
import {Link} from 'react-router-dom';

import {getCellRenderer} from './cellRendering';
import {isFilterable, getFilterMethod, getFilterRenderer} from './filterRendering';

export function buildFromData(dataUrl, columns, readOnly) {
    const formatedColumns = columns.map(formatColumn);
    formatedColumns.push({
        Header: '', 
        accessor: 'id',
        filterable: false,
        maxWidth: 80,
        Cell: row => (<Link to={`/${dataUrl}/${row.value}`}>{ readOnly ? 'Show' : 'Edit' }</Link>)
    });
    return formatedColumns;
}

function formatColumn(column) {
    return {
        Header: <b className="text-center">{column.label}</b>, 
        accessor: column.field,
        maxWidth: getMaxWidth(column.type),
        Cell: getCellRenderer(column.type, column.transform),
        filterable: isFilterable(column.type),
        filterMethod: getFilterMethod(column.type),
        Filter: getFilterRenderer(column.type)
    };
}

function getMaxWidth(type) {
    switch(type) {
        case 'bool': return 200;
        default: return 10000;
    }
}