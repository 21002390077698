import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {Col, Form, Row} from 'reactstrap';

class PopForm extends FormBase {
    render() {
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}><Row>
                            <Col sm={12}>{this.input('name', {label: 'Name'})}</Col>
                            <Col sm={6}>{this.input('state', {label: 'State'})}</Col>
                            <Col sm={6}>{this.input('city', {label: 'City'})}</Col>
                            <Col sm={12}>{this.input('colo', {label: 'Colo'})}</Col>

                        </Row></Col>
                        <Col sm={6}><Row>
                            <Col sm={6}>{this.number('latitude', {label: 'Latitude'})}</Col>
                            <Col sm={6}>{this.number('longitude', {label: 'Longitude'})}</Col>
                            <Col sm={12}>{this.textArea('description', {label: 'Description'})}</Col>
                        </Row></Col>
                    </Row>
                )}
            </Form>
        );
    }
}

export default PopForm