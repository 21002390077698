import * as React from 'react';
import {Link} from 'react-router-dom';
import {Button, ListGroup, ListGroupItem} from 'reactstrap';

const BandsList = ({bands}) => (
    <ListGroup>
        { bands.map(band => (
            <ListGroupItem key={band.id} className="d-flex justify-content-between align-items-center">
                <span>{band.name.toUpperCase()}-band</span>
                <Link to={`/bands/${band.id}`} className="ml-2">
                    <Button type="button" outline>
                        Edit
                    </Button>
                </Link>
            </ListGroupItem>
        ))}
    </ListGroup>
);

export default BandsList;