import * as React from 'react';
import { fetchOrbitalPositions } from '../../actions/orbitalPositions';
import { fetchManufacturers } from '../../actions/manufacturers';
import { fetchCoverageAreas } from '../../actions/coverageAreas';
import { fetchFootprints } from '../../actions/footprints';
import { createSatellite, fetchSatellite, updateSatellite, deleteSatellite, restoreSatellite } from '../../actions/satellites';
import SatelliteForm from '../../components/satellites/SatelliteForm';
import CommonEditPage from '../shared/CommonEditPage';
import moment from 'moment/moment';
import { fetchConstellations } from '../../actions/constellations';
import {fetchLaunches} from "../../actions/launches";

const INITIAL_DATA = {
    type: 'GEO',
    name: '',
    title: '',
    launchDate: null,
    launchDateText: '',
    vehicleId: null,
    designLife: 0,
    manufacturerId: null,
    orbitalPositionId: null,
    commercialized: true,
    inclined: false,
    transpondersCBand: 0,
    transpondersKaBand: 0,
    transpondersKuBand: 0,
    description: '',
    bandComment: '',
    deleted: false
};

const SatelliteEditPage = (props) => (
    <CommonEditPage
        {...props}

        mainDataInfo={{
            name: 'data',
            title: 'satellite',
            rootPath: '/satellites',
            default: INITIAL_DATA,
            promise: (id) => fetchSatellite(id).then(satellite => ({
                ...satellite,
                launchDate: satellite.launchDate ? moment(satellite.launchDate).toDate() : null
            }))
        }}
        secDataInfos={[
            { name: 'orbitalPositions', title: 'orbital positions', promise: () => fetchOrbitalPositions() },
            { name: 'manufacturers', title: 'manufacturers', promise: () => fetchManufacturers() },
            { name: 'coverageAreas', title: 'coverage areas', promise: () => fetchCoverageAreas() },
            { name: 'footprints', title: 'footprints', promise: () => fetchFootprints() },
            { name: 'constellations', title: 'constellations', promise: () => fetchConstellations() },
            { name: 'launches', title: 'launches', promise: () => fetchLaunches() }
        ]}
        formComponent={(props) => (<SatelliteForm {...props} />)}
        updateCallback={updateSatellite}
        createCallback={createSatellite}
        deleteCallback={deleteSatellite}
        restoreCallback={restoreSatellite}
        buildFormData={buildFormData}
    />
)

function buildFormData(data) {
    const formData = new FormData();
    Object.keys(data)
        .filter(key => key !== "launchDate")
        .forEach(key => formData.append(key, data[key] === null ? '' : data[key]));

    formData.set('launchDate', data.launchDate ? data.launchDate.toISOString() : null);

    return formData;
}

export default SatelliteEditPage;