import React from 'react'
import { CommonTable } from '../../components/shared/CommonTable';
import { Alert, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export const CommonListPage = (props) => (
    <div>
        <Error {...props} />
        <Data {...props} />
    </div>
)

const Error = ({ dataName, error }) => (
    <div>
        {!!error && <Alert color="danger">An error occurred while fetching the {dataName}s.</Alert>}
    </div>
)

const Data = (props) => (
    <div>
        <div>
            <h1>Listing {props.dataName}s ({props.dataSize || 0})</h1>
            {props.readOnly ? null :
                <div>
                    <Link to={`/${props.dataUrl}/new`}>
                        <Button color="primary" className="mb-2 mr-2">
                            New {props.dataName}
                        </Button>
                    </Link>
                    {props.duplicate ?
                        <Link to={`/${props.dataUrl}/duplicate`}>
                            <Button color="outline-primary" className="mb-2">
                                Duplicate {props.dataName}
                            </Button>
                        </Link> : null}
                    {props.listHeader}
                </div>
            }
            <CommonTable {...props} />
        </div>
    </div>
)