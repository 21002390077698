import * as React from 'react'
import ManufacturerForm from '../../components/manufacturers/ManufacturerForm';
import {fetchManufacturer, updateManufacturer, createManufacturer, deleteManufacturer} from '../../actions/manufacturers';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const ManufacturerEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'manufacturer',
            rootPath: '/manufacturers',
            default: INITIAL_DATA,
            promise: (id) => fetchManufacturer(id)    
        }}
        formComponent={(props) => ( <ManufacturerForm {...props} /> )}
        updateCallback={updateManufacturer} 
        createCallback={createManufacturer}           
        deleteCallback={deleteManufacturer}         
    />
)

export default ManufacturerEditPage;
