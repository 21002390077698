import axios from 'axios';

export function fetchFootprints() {
    return axios.get(`/webservice/backoffice/footprints`).then(response => response.data);
}

export function fetchFootprint(id) {
    return axios.get(`/webservice/backoffice/footprints/${id}`).then(response => response.data);
}

export function createFootprint(formData) {
    return axios.post(`/webservice/backoffice/footprints`, formData);
}

export function updateFootprint(id, formData) {
    return axios.post(`/webservice/backoffice/footprints/${id}`, formData);
}

export function deleteFootprint(id) {
    return axios.delete(`/webservice/backoffice/footprints/${id}`);
}
