import * as React from 'react'
import VehicleForm from '../../components/vehicles/VehicleForm';
import {fetchVehicle, updateVehicle, createVehicle, deleteVehicle} from '../../actions/vehicles';
import CommonEditPage from '../shared/CommonEditPage';

const INITIAL_DATA = {};

const VehicleEditPage = (props) => (
    <CommonEditPage         
        {...props}

        mainDataInfo={{
            name: 'data', 
            title: 'vehicle',
            rootPath: '/vehicles',
            default: INITIAL_DATA,
            promise: (id) => fetchVehicle(id)    
        }}
        formComponent={(props) => ( <VehicleForm {...props} /> )}
        updateCallback={updateVehicle} 
        createCallback={createVehicle}        
        deleteCallback={deleteVehicle}            
    />
)

export default VehicleEditPage;