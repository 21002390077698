import * as React from 'react';
import {FormBase} from '../shared/FormBase';
import {CardDeck, Col, Form, Row} from 'reactstrap';
import {objListToMap} from '../../services/MapExtractor';

class FootprintForm extends FormBase {
    render() {
        const { data, satellites, geoJsons } = this.props;
        return (
            <Form>
                {this.formCard("General",
                    <Row>
                        <Col sm={6}>{this.input('name', {label: 'Name'})}</Col>
                        <Col sm={6}>{this.input('shortName', {label: 'Short name'})}</Col>
                        <Col sm={6}>{this.input('title', {label: 'Title'})}</Col>
                        <Col sm={6}>{this.input('region', {label: 'Region'})}</Col>
                        <Col sm={6}>{this.input('coverageType', {label: 'Covergage type'})}</Col>

                        <Col sm={4}>{this.select('labelEirp', {'EIRP': 'EIRP', 'Dish size': 'Dish size'}, {label: 'Legend EIRP', placeholder: 'Please select...'})}</Col>
                        <Col sm={4}>{this.select('band', {'c': 'C-band', 'ka': 'Ka-band', 'ku': 'Ku-band'}, {label: 'Band', placeholder: 'Please select...'})}</Col>
                        <Col sm={4}>{this.select('feature', {'ip': 'IP', 'video': 'Video', 'video_and_ip': 'Video and IP'}, {label: 'Footprint feature', placeholder: 'Please select...'})}</Col>

                        <Col sm={6}>{this.select('satelliteId', objListToMap(satellites, 'id', 'name'), {label: 'Satellite', placeholder: 'No satellite'})}</Col>
                        <Col sm={6}>{this.select('geoJsonId', objListToMap(geoJsons, 'id', 'name'), {label: 'Geo-json', placeholder: 'No geo-json'})}</Col>
                    </Row>
                )}

                <CardDeck>
                    {this.formCard("Extra information",
                        <Row>
                            <Col sm={12}>{this.input('description', {label: 'Description'})}</Col>
                            <Col sm={12}>{this.input('oneSatPlanName', {label: 'One Sat Plan name'})}</Col>
                        </Row>
                    )}
                    {this.formCard("Image",
                        <div>
                            {data.imageUrl && <img src={data.imageUrl} alt='footprint'/>}
                            {this.fileChooser('image', {label: 'Change image'})}
                        </div>
                    )}
                </CardDeck>
            </Form>
        );
    }
}

export default FootprintForm